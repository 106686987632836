import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  width: 90vw;
  margin-top: 4rem;
  margin-bottom: 4rem;

  @media (min-width: 992px) {
    width: 70vw;
  }
`;

const PageWrapper = ({ children }) => <Container>{children}</Container>;

PageWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PageWrapper;

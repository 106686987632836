import React, { useState, useEffect, Suspense } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

/* Layout Components */
import GlobalStyles from './Components/Theme/GlobalStyle';
import { lightTheme, darkTheme } from './Components/Theme/Theme';
import SiteWrapper from './Components/Layout/SiteWrapper';
import PageWrapper from './Components/Layout/PageWrapper';
import NavBar from './Components/Layout/NavBar';
import Footer from './Components/Layout/Footer';
import Loading from './Pages/Loading';

/* Page Components (lazy) */
import Home from './Pages/Home';
import Projects from './Pages/Projects';
import Blog from './Pages/Blog';
import Music from './Pages/Music';
import ProjectContent from './Components/Projects/ProjectContent';
import ArticleContent from './Components/Blog/ArticleContent';

const App = () => {
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme((prev) => (prev === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    const prefersDark =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (prefersDark) setTheme('dark');
  }, []);

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      <SiteWrapper>
        <HashRouter>
          <NavBar currentTheme={theme} toggleTheme={toggleTheme} />

          <PageWrapper>
            <Suspense fallback={<Loading />}>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/projects">
                  <Projects />
                </Route>
                <Route exact path="/blog">
                  <Blog />
                </Route>
                <Route path="/music">
                  <Music />
                </Route>
                <Route path="/projects/:projectId">
                  <ProjectContent />
                </Route>
                <Route path="/blog/:articleId">
                  <ArticleContent />
                </Route>
              </Switch>
            </Suspense>
          </PageWrapper>

          <Footer />
        </HashRouter>
      </SiteWrapper>
    </ThemeProvider>
  );
};

export default App;
